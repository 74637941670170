var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"availability full-screen-dialog",attrs:{"visible":_vm.visible,"before-close":_vm.handleClose,"append-to-body":true,"width":"100%"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"caption"},[_c('h4',[_vm._v("Welke beschikbaarheid wil je aanpassen?")])]),_c('h5',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.capitalize(_vm.editingDay.day.format("dddd D MMMM")))+" ")]),_c('p',{staticClass:"mt-4"},[_vm._v(" 'S ochtends "),_c('span',{staticClass:"daypart"},[_vm._v("(08:00 - 12:30)")])]),_c('div',{staticClass:"d-flex flex-row mb-4"},[(_vm.editingDay.dayParts.morning.disabled)?[_c('div',{staticClass:"col-12"},[_c('p',[_vm._v("Dagdeel reeds ingepland")])])]:[_c('div',{staticClass:"mr-15"},[_c('a',{staticClass:"btn",class:{
              'btn-primary': _vm.editingDay.dayParts.morning.value,
              'btn-outline-primary': !_vm.editingDay.dayParts.morning.value
            },attrs:{"disabled":_vm.editingDay.dayParts.morning.disabled},on:{"click":function($event){$event.preventDefault();!_vm.editingDay.dayParts.morning.disabled
                ? _vm.setAvailability('morning', true)
                : ''}}},[_vm._v(" Beschikbaar")])]),_c('div',[_c('a',{staticClass:"btn",class:{
              'btn-primary': _vm.editingDay.dayParts.morning.value == false,
              'btn-outline-primary':
                _vm.editingDay.dayParts.morning.value != false
            },attrs:{"disabled":_vm.editingDay.dayParts.morning.disabled},on:{"click":function($event){$event.preventDefault();return _vm.setAvailability('morning', false)}}},[_vm._v(" Niet beschikbaar")])])]],2),_c('p',{staticClass:"mt-4"},[_vm._v(" 'S middags "),_c('span',{staticClass:"daypart"},[_vm._v("(13:00 - 17:30)")])]),_c('div',{staticClass:"d-flex flex-row mb-4"},[(_vm.editingDay.dayParts.afternoon.disabled)?[_c('div',{staticClass:"col-12"},[_c('p',[_vm._v("Dagdeel reeds ingepland")])])]:[_c('div',{staticClass:"mr-15"},[_c('a',{staticClass:"btn",class:{
              'btn-primary': _vm.editingDay.dayParts.afternoon.value,
              'btn-outline-primary': !_vm.editingDay.dayParts.afternoon.value
            },on:{"click":function($event){$event.preventDefault();return _vm.setAvailability('afternoon', true)}}},[_vm._v(" Beschikbaar")])]),_c('div',[_c('a',{staticClass:"btn",class:{
              'btn-primary': _vm.editingDay.dayParts.afternoon.value == false,
              'btn-outline-primary':
                _vm.editingDay.dayParts.afternoon.value != false
            },on:{"click":function($event){$event.preventDefault();return _vm.setAvailability('afternoon', false)}}},[_vm._v(" Niet beschikbaar")])])]],2),(!_vm.editingDay.dayParts.successive.disabled && !_vm.bothDisabled)?[_c('p',{staticClass:"mt-4"},[_vm._v(" Bovenstaande aanpassingen(en) toepassen voor elke aankomende "+_vm._s(_vm.editingDay.day.format("dddd"))+" van deze maand. ")]),_c('div',{staticClass:"d-flex flex-row mb-4"},[_c('div',{staticClass:"mr-15"},[_c('a',{staticClass:"btn",class:{
              'btn-primary': _vm.editingDay.dayParts.successive.value,
              'btn-outline-primary': !_vm.editingDay.dayParts.successive.value
            },on:{"click":function($event){$event.preventDefault();return _vm.setAvailability('successive', true)}}},[_vm._v(" Ja")])]),_c('div',[_c('a',{staticClass:"btn",class:{
              'btn-primary': _vm.editingDay.dayParts.successive.value == false,
              'btn-outline-primary':
                _vm.editingDay.dayParts.successive.value != false
            },on:{"click":function($event){$event.preventDefault();return _vm.setAvailability('successive', false)}}},[_vm._v(" Nee")])])])]:_vm._e()],2),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"row justify-content-between align-items-right"},[_c('div',{staticClass:"col"},[(_vm.bothDisabled)?_c('a',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.handleClose($event)}}},[_vm._v("Sluiten")]):_c('a',{staticClass:"btn btn-warning",on:{"click":function($event){$event.preventDefault();return _vm.saveDayParts($event)}}},[_vm._v("Opslaan")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }