







































import { Component, Prop, Vue } from "vue-property-decorator";
import Calendar from "@/common/components/Calendar/Calendar.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment, { Moment } from "moment";

@Component({
  components: {
    Calendar
  }
})
export default class CalendarDialog extends Vue {
  @Prop() title!: string;
  @Prop() activeDate!: Moment;
  @Prop() currentMonth!: Moment;
  @Prop() daysWithEvents!: Moment;
  @Prop() visible!: boolean;
  @Prop() disabledBeforeDate!: Moment;
  @Prop() disabledAfterDate!: Moment;

  previousMonth() {
    this.$emit("previousMonth");
  }

  nextMonth() {
    this.$emit("nextMonth");
  }

  handleClose() {
    this.$emit("update:currentMonth", this.activeDate.clone().startOf("month"));
    this.$emit("update:visible", false);
  }

  selectDate(day: Moment) {
    this.$emit("selectDate", day);
  }
}
