






import { Component, Vue } from "vue-property-decorator";

import DirectorDashboard from "@/modules/director/views/dashboard/Dashboard.vue";
import ReplacerDashboard from "@/modules/replacer/views/dashboard/Dashboard.vue";

import { State } from "vuex-class";
import { UserState } from "@/common/store/user/types";

@Component({
  components: {
    DirectorDashboard,
    ReplacerDashboard
  }
})
export default class Dashboard extends Vue {
  @State("user") user!: UserState;

  getDashboard() {
    if (this.user.permissions.includes("CAN_VIEW_ABSENCES")) {
      return DirectorDashboard;
    } else if (this.user.permissions.includes("CAN_SUBSTITUTE")) {
      return ReplacerDashboard;
    }
  }
}
