import Vue from "vue";
import { Component } from "vue-property-decorator";
import { State } from "vuex-class";
import moment, { Moment } from "moment";
import {
  ReplacementInfoInterface,
  PeriodInterface
} from "@/modules/director/interfaces/ReplacementInfoInterface";
import { UserState } from "@/common/store/user/types";

import Calendar from "@/common/components/Calendar/Calendar.vue";

import { findIndex, forEach } from "lodash";
@Component({
  components: {
    Calendar
  }
})
export class DashboardMixin extends Vue {
  @State("user") user!: UserState;

  replacements: { [key: string]: ReplacementInfoInterface[] } = {};

  activeDate: Moment = moment();
  currentMonth: Moment = moment().startOf("month");

  activeInfoCard: ReplacementInfoInterface = {
    replacer: "",
    substituting: "",
    school: "",
    address: "",
    place: "",
    startDate: moment(),
    endDate: moment(),
    periods: [],
    class: "",
    type: "",
    icon: "",
    morningDaypart: true,
    afternoonDaypart: true,
    id: 0
  };

  dialogVisible = false;
  commentDialogVisible = false;

  get days() {
    const days: {
      day: Moment;
      dayParts: {
        morning: boolean;
        afternoon: boolean;
      };
    }[] = [];

    forEach(this.activeInfoCard.periods, function(
      availabilityDay: PeriodInterface
    ) {
      const found = findIndex(days, function(day: {
        day: Moment;
        dayParts: { morning: boolean; afternoon: boolean };
      }) {
        return moment(availabilityDay.date).isSame(day.day, "day");
      });

      if (found >= 0) {
        if (availabilityDay.periodOfDay === 1) {
          days[found].dayParts.morning = availabilityDay.confirmed;
        } else if (availabilityDay.periodOfDay === 2) {
          days[found].dayParts.afternoon = availabilityDay.confirmed;
        }
      } else {
        days.push({
          day: moment(availabilityDay.date),
          dayParts: {
            morning: availabilityDay.periodOfDay === 1,
            afternoon: availabilityDay.periodOfDay === 2
          }
        });
      }
    });

    return days;
  }

  handleClose() {
    this.dialogVisible = false;
  }

  handleCommentClose() {
    this.commentDialogVisible = false;
  }

  getMonth(date: Moment) {
    return moment(date).startOf("month");
  }

  formatDate(date: Moment, format: string) {
    return moment(date).format(format);
  }

  previousMonth() {
    this.currentMonth = this.currentMonth.clone().subtract(1, "M");
  }

  nextMonth() {
    this.currentMonth = this.currentMonth.clone().add(1, "M");
  }
}
