


















































































import { Component, Mixins } from "vue-property-decorator";

import { DashboardMixin } from "@/common/mixins/dashboard/DashboardMixin";

import CalendarDialog from "@/common/components/Calendar/CalendarDialog.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment, { Moment } from "moment";
import axios from "@/libs/axios/axios";
import { AxiosError, AxiosResponse } from "axios";
import { ReplacementInfoInterface } from "@/modules/director/interfaces/ReplacementInfoInterface";
import { findIndex } from "lodash";

interface DayInterface {
  day: Moment;
  dayParts: {
    morning: boolean;
    afternoon: boolean;
  };
}

@Component({
  components: {
    CalendarDialog
  }
})
export default class Dashboard extends Mixins(DashboardMixin) {
  calendarDialogVisible = false;
  activeInfoCardMonth = moment();
  absencesLoading = false;

  async mounted() {
    await this.getAbsences();
  }

  async getAbsences() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      this.absencesLoading = true;
      const response: void | AxiosResponse = await axios
        .get("absences", {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          },
          params: {
            date: this.activeDate.format("YYYY-MM-DD")
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (response && response.data) {
        this.replacements = response.data.items;
      }
      this.absencesLoading = false;
    }
  }

  getDotColor(absence: ReplacementInfoInterface) {
    let classes = "";
    const morningIndex = findIndex(absence.onThisDay, { periodOfDay: 1 });

    if (
      absence &&
      absence.onThisDay &&
      absence.onThisDay.length > 0 &&
      absence.onThisDay[morningIndex]
    ) {
      if (absence.onThisDay[morningIndex].status === "rejected") {
        classes += "morning_red";
      } else if (absence.onThisDay[morningIndex].status === "confirmed") {
        classes += "morning_green";
      } else if (
        absence.onThisDay[morningIndex].status === "pending" ||
        absence.onThisDay[morningIndex].status === null
      ) {
        classes += "morning_orange";
      }
    }

    const afternoonIndex = findIndex(absence.onThisDay, { periodOfDay: 2 });

    if (
      absence &&
      absence.onThisDay &&
      absence.onThisDay.length > 0 &&
      absence.onThisDay[afternoonIndex]
    ) {
      if (absence.onThisDay[afternoonIndex].status === "rejected") {
        classes += " afternoon_red";
      } else if (absence.onThisDay[afternoonIndex].status === "confirmed") {
        classes += " afternoon_green";
      } else if (
        absence.onThisDay[afternoonIndex].status === "pending" ||
        absence.onThisDay[afternoonIndex].status === null
      ) {
        classes += " afternoon_orange";
      }
    }

    return classes;
  }

  activeInfoCardPreviousMonth() {
    this.activeInfoCardMonth = this.activeInfoCardMonth
      .clone()
      .subtract(1, "M");
  }

  activeInfoCardNextMonth() {
    this.activeInfoCardMonth = this.activeInfoCardMonth.clone().add(1, "M");
  }

  previousDay() {
    let previousDay = this.activeDate.clone().subtract(1, "days");
    if (previousDay.day() == 0) {
      previousDay = previousDay.subtract(2, "days");
    } else if (previousDay.day() == 6) {
      previousDay = previousDay.subtract(1, "days");
    }

    this.currentMonth = previousDay.clone().startOf("month");
    this.changeDate(previousDay);
  }

  nextDay() {
    let nextDay = this.activeDate.clone().add(1, "days");
    if (nextDay.day() == 0) {
      nextDay = nextDay.add(1, "days");
    } else if (nextDay.day() == 6) {
      nextDay = nextDay.add(2, "days");
    }

    this.currentMonth = nextDay.clone().startOf("month");
    this.changeDate(nextDay);
  }

  changeDate(date: Moment) {
    this.activeDate = date.startOf("day");
    this.calendarDialogVisible = false;
    this.getAbsences();
  }
}
