























































import { Component, Mixins } from "vue-property-decorator";

import { DashboardMixin } from "@/common/mixins/dashboard/DashboardMixin";

import moment, { Moment } from "moment";
import axios from "@/libs/axios/axios";
import { AxiosResponse, AxiosError } from "axios";

import AvailabilityDialog from "@/common/components/Dialogs/AvailabilityDialog.vue";

interface DayInterface {
  day: Moment;
  dayParts: {
    morning: boolean;
    afternoon: boolean;
  };
}

@Component({
  components: {
    AvailabilityDialog
  }
})
export default class Dashboard extends Mixins(DashboardMixin) {
  activeInfoCardMonth = moment();

  async mounted() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const responseSubstitutions: void | AxiosResponse = await axios
        .get("substitutions/reviewed", {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          },
          params: {
            start: this.formatDate(moment(), "YYYY-MM-DD"),
            end: this.formatDate(moment().add(1, "year"), "YYYY-MM-DD")
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (responseSubstitutions && responseSubstitutions.data) {
        this.replacements = responseSubstitutions.data.items;
      }
    }
  }

  calculateDate(startDate: Moment, endDate: Moment) {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);

    if (moment().isBefore(startDateMoment)) {
      return (
        "<strong>" +
        startDateMoment.format("DD MMM") +
        "</strong><br /> \n <div class='small-text'>over " +
        startDateMoment.diff(moment(), "days") +
        " dagen</span>"
      );
    } else if (moment().isAfter(endDateMoment)) {
      return "Verlopen";
    } else if (moment().isBetween(startDateMoment, endDateMoment, "day")) {
      return "<div class='is-today'>Vandaag</div>";
    }
  }
}
